import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import App from './App';
import getSchema from './getSchema';

const cookies = new Cookies();
window.DAEXUS_API_URL =
  cookies.get('daexus-api-url') || process.env.REACT_APP_API_URL;
window.EXTENDED_LOGS = cookies.get('daexus-extended-logs');

const myConnector = window.tableau.makeConnector();

myConnector.init = async (initCallback) => {
  window.tableau.authType = window.tableau.authTypeEnum.none;

  if (window.tableau.phase === window.tableau.phaseEnum.gatherDataPhase) {
    initCallback();
  } else {
    ReactDOM.render(<App />, document.getElementById('root'), initCallback);
  }
};

myConnector.getSchema = (schemaCallback) => {
  const schema = getSchema(['metric1'], [], 'none');

  schemaCallback(schema);
};

myConnector.getData = async (
  { tableInfo, appendRows, incrementValue },
  doneCallback,
) => {
  appendRows([{ metric1: 123 }]);
  doneCallback();
};

window.tableau.registerConnector(myConnector);
