import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import teal from '@material-ui/core/colors/teal';
import './tableauWorkaround.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
    secondary: {
      main: teal[500],
    },
    background: {
      default: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container fixed maxWidth="sm">
        <CssBaseline />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.tableau.connectionName = 'Daexus Catalyst Connector';
            window.tableau.submit();
          }}
        >
          Submit
        </Button>
      </Container>
    </ThemeProvider>
  );
}

export default App;
